var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Z8C6qB0b-B-92gLVtOgGR"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/prod/consumer-webapp";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/browser';
import posthog from 'posthog-js';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ORG_SLUG = process.env.NEXT_PUBLIC_SENTRY_ORG_SLUG;
const POSTHOG_API_HOST = process.env.NEXT_PUBLIC_POSTHOG_API_HOST;
const POSTHOG_WRITE_KEY = process.env.NEXT_PUBLIC_POSTHOG_WRITE_KEY;

const isProd = process.env.NODE_ENV === 'production';

posthog.init(POSTHOG_WRITE_KEY, {
  api_host: POSTHOG_API_HOST,
  ui_host: 'https://app.posthog.com',
  capture_pageview: false,
  opt_in_site_apps: true,
  loaded: (client) => {
    if (!isProd) client.opt_out_capturing();
  },
});

if (SENTRY_DSN) {
  Sentry.init({
    debug: false,
    dsn: SENTRY_DSN,
    attachStacktrace: true,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      // The following errors are caused by Amazon web crawlers and should be ignored
      "Cannot read properties of undefined (reading 'ns')",
      "Cannot read properties of undefined (reading 'outputCurrentConfiguration')",
      "Cannot read properties of undefined (reading 'variables')",
      "Cannot read properties of undefined (reading 'mvpConfig')",
      "Cannot read properties of undefined (reading 'config')",
      "Cannot read properties of undefined (reading 'get')",
      "Cannot read properties of undefined (reading 'loaded')",
      'apstagLOADED is not defined',
      'IMUID is not defined',
      '__cmp is not defined',
      'jwDefaults is not defined',
      'YT is not defined',
      'moat_px is not defined',
    ],
    replaysSessionSampleRate: 0.005,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new posthog.SentryIntegration(posthog, SENTRY_ORG_SLUG, SENTRY_DSN),
      new BrowserTracing(),
      new Sentry.Replay(),
    ],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.005,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
